import Torm from '../../images/clients/client-1.png';
import BritishMarine from '../../images/clients/British-Marine-2.png';
import CochinShipyard from '../../images/clients/Cochin-shipyard-limited-2-300x69.png';
import HALOffshore from '../../images/clients/HAL-Offshore-Limited-2.png';
import MD from '../../images/clients/MD-1.png';
import Maersk from '../../images/clients/Maersk-1.png';
import NDPL from '../../images/clients/NDPL-1.png';
import Scorpio from '../../images/clients/Scorpio-1-300x52.png';
import SevenLands from '../../images/clients/Seven-Lands-1.png';
import Ships from '../../images/clients/Ships-1.png';
import ShreeramGroup from '../../images/clients/Shreeram-group-2.png';
import TheGreatEasternShippingCompany from '../../images/clients/The-Great-Eastern-Shipping-Company-Limited-2-2.jpg';
import BernhardShulteShipManagement from '../../images/clients/bernhard-shulte-ship-management-2.png';
import HindOffshore from '../../images/clients/hind-offshore-300x56.png';
import ShippingCorporation from '../../images/clients/shipping-corporation-of-india-logo-AD21B51F13-seeklogo.com_-2.png';
import SynergyGroup from '../../images/clients/synergy-group-2.png';
import TransworldShipManagement from '../../images/clients/transworld-ship-management-2-300x43.png';
import OffShoreService from '../../images/services/Offshore-Services-790x980-1.png';
import FutureTechSolution from '../../images/services/Future-Technology-Solutions-790x980-1.png';
import MarineElectrical from '../../images/services/Marine-Electrical-Automations-and-Control-Services-790x980-1.png';
import MarineService from '../../images/services/Marine-Services-790x980-1.jpg';


export {OffShoreService,FutureTechSolution, MarineElectrical, MarineService, Torm,BritishMarine, CochinShipyard, HALOffshore, MD, Maersk, NDPL, Scorpio, SevenLands, Ships, ShreeramGroup, TheGreatEasternShippingCompany, TransworldShipManagement, SynergyGroup, ShippingCorporation, HindOffshore, BernhardShulteShipManagement}