export const base_url = `http://127.0.0.1:8000/`;
// export const base_url = `https://admin.beenetmunication.com/`;

const api_url = base_url+`api/`;

export const URL = {
    careers:api_url+`careers`,
    job:api_url+`job`,
    blogs:api_url+`blogs`,
    solutions:api_url+`solutions`,
}